import React, { FunctionComponent } from 'react';
import rootStore from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const ThisCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, , learningState] = rootStore.state.statePath;

  const nextReptInDays =
    (rootStore.rept?.dateNumber ?? 0) -
    (rootStore.currentStack.data?.currentDayNr ?? 0);

  const lastRept =
    rootStore.rept?.history.length ?? -1 > 0
      ? rootStore.rept!.history[rootStore.rept!.history.length - 1].createdOn
      : '';

  const descr =
    learningState === 'revision'
      ? t('next repetition (in days)')
      : t('last repetition');

  const dateString = learningState === 'revision' ? nextReptInDays : lastRept;

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2}>{t('this card')}</td>
        </tr>
        <tr>
          <td>{descr}</td>
          <td className="text-right">{dateString}</td>
        </tr>
        <tr>
          <td>{t('total repetitions')}</td>
          <td className="text-right">{rootStore.rept?.history.length ?? 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default observer(ThisCard);
