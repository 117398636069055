import React, { FunctionComponent, useState } from 'react';
import { Intro } from '../Intro';
import { Footer } from '../../Footer/Footer';
import Header from '../../Header/Header';

const IntroLayout: FunctionComponent = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex flex-col h-full w-full">
      <Header
        onClick={() => {
          setMenuOpen((isMO) => !isMO);
        }}
        menuOpen={isMenuOpen}
      />

      <div className="flex-grow relative p-2">
        <Intro />
      </div>

      <Footer />
    </div>
  );
};

export default IntroLayout;
