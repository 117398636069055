import React, { FunctionComponent } from 'react';
import ModalPanel from './ModalPanel';
import { useTranslation } from 'react-i18next';
import rootStore from '../../store/rootStore';
import { config } from '../../config/config';
import {
  button,
  buttonCancel,
  buttonDanger,
  smallText,
} from '../../twStyles/twStyles';
import { runInAction } from 'mobx';
import { InviWithProfile } from '../../database/model/EntityTypes';
import mutate from '../../store/mutate';
import UserShortProfile from '../UserShortProfile/UserShortProfile';

type OwnProperties = {
  invi: InviWithProfile;
};

const AcceptInvitation: FunctionComponent<OwnProperties> = ({ invi }) => {
  const { t } = useTranslation();

  const { reptsPerDay } =
    rootStore.user.data?.settings ?? config.defaultSettings;

  const buttons = (
    <>
      <button
        data-test="accept_invi"
        type="button"
        className={buttonDanger}
        onClick={() => {
          mutate.acceptInvitation({
            inviId: invi.inviId,
          });
          rootStore.closeModal();
        }}
      >
        {t('Accept')}
      </button>
      <button
        type="button"
        className={buttonCancel}
        onClick={() => {
          runInAction(() => {
            rootStore.closeModal();
          });
        }}
      >
        {t('Ignore')}
      </button>
    </>
  );

  return (
    <ModalPanel buttons={buttons}>
      <UserShortProfile profile={invi.userProfile} />
      <p data-test="invi_info" className={smallText}>
        {t('has invited you to learn words and share cards together.', {
          reptsPerDay,
        })}
      </p>
    </ModalPanel>
  );
};

export default AcceptInvitation;
