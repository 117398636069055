import React, { FunctionComponent } from 'react';
import * as styles from 'src/twStyles/twStyles';

interface OwnProps {
  header?: string;
}

type Props = OwnProps;

const FormElement: FunctionComponent<Props> = ({ header, children }) => (
  <div className="flex flex-col w-full mt-2 px-4 py-2 border rounded-lg bg-white w-full">
    {header && <div className={styles.label}>{header}</div>}
    {children}
  </div>
);

export default FormElement;
