import { doFetch } from './fetch';
import { ApiMethod, NoteType } from '../database/model/EntityTypes';
import rootStore from './rootStore';
import {
  apiDoSchedule,
  ApiDoScheduleOutputType,
} from '../api/mtd/apiDoSchedule';
import { apiAddCard } from '../api/mtd/apiAddCard';
import { apiAddStack } from '../api/mtd/apiAddStack';
import { apiIncreaseCurrentDayNr } from '../api/mtd/apiIncreaseCurrentDayNr';
import { apiUpdateProfile } from '../api/mtd/apiUpdateProfile';
import { apiGetInvitation } from '../api/mtd/apiGetInvitation';
import { apiAcceptInvitation } from '../api/mtd/apiAcceptInvitation';
import { apiGetNotes } from '../api/mtd/apiGetNotes';
import { apiMarkNoteAsRead } from '../api/mtd/apiMarkNoteAsRead';
import { apiChangeSetting } from '../api/mtd/apiChangeSetting';
import { apiDeclineSugg } from '../api/mtd/apiDeclineSugg';
import { apiAddCardToQueue } from '../api/mtd/apiAddCardToQueue';

type RemoveAuthIdFromFirstParameter<
  T extends (p: Parameters<T>[0]) => ReturnType<T>
> = (p: Omit<Parameters<T>[0], 'authId'>) => Promise<void>;

type MutateType = {
  addCard: RemoveAuthIdFromFirstParameter<typeof apiAddCard>;
  addStack: RemoveAuthIdFromFirstParameter<typeof apiAddStack>;
  doSchedule: RemoveAuthIdFromFirstParameter<typeof apiDoSchedule>;
  increaseCurrentDayNr: RemoveAuthIdFromFirstParameter<
    typeof apiIncreaseCurrentDayNr
  >;
  updateProfile: RemoveAuthIdFromFirstParameter<typeof apiUpdateProfile>;
  getInvitation: RemoveAuthIdFromFirstParameter<typeof apiGetInvitation>;
  acceptInvitation: RemoveAuthIdFromFirstParameter<typeof apiAcceptInvitation>;
  getNotes: RemoveAuthIdFromFirstParameter<typeof apiGetNotes>;
  markNoteAsRead: RemoveAuthIdFromFirstParameter<typeof apiMarkNoteAsRead>;
  changeSetting: RemoveAuthIdFromFirstParameter<typeof apiChangeSetting>;
  declineSugg: RemoveAuthIdFromFirstParameter<typeof apiDeclineSugg>;
  addCardToQueue: RemoveAuthIdFromFirstParameter<typeof apiAddCardToQueue>;
};

const mutate: MutateType = {
  addCard: async (par) => {
    await doFetch(ApiMethod.addCard, par);
    rootStore.nextRept.fetchData();
    rootStore.currentStack.fetchData();
  },

  addStack: async (par) => {
    await doFetch(ApiMethod.addStack, par);
    rootStore.collId = par.stack.collId;
    rootStore.userStacks.fetchData();
    rootStore.user.fetchData();
  },

  doSchedule: async (par) => {
    // @ts-ignore
    const res: ApiDoScheduleOutputType = await doFetch(
      ApiMethod.doSchedule,
      par
    );
    rootStore.currentStack.fetchData();
    rootStore.nextRept.fetchData();
    rootStore.rept = res;
  },

  increaseCurrentDayNr: async (par) => {
    await doFetch(ApiMethod.increaseCurrentDayNr, par);

    rootStore.currentStack.fetchData();
    rootStore.nextRept.fetchData();
  },

  updateProfile: async (par) => {
    await doFetch(ApiMethod.updateProfile, par);

    rootStore.user.fetchData();
  },

  getInvitation: async (par) => {
    const invi = await doFetch(ApiMethod.getInvitation, par);

    if (invi) {
      rootStore.showInvitation(invi);
    }
  },

  acceptInvitation: async (par) => {
    await doFetch(ApiMethod.acceptInvitation, par);

    rootStore.user.fetchData();
  },

  getNotes: async () => {
    const notes = (await doFetch(ApiMethod.getNotes)) as NoteType[];
    rootStore.addNotes(notes);
  },

  markNoteAsRead: async (par) => doFetch(ApiMethod.markNoteAsRead, par),

  changeSetting: async (par) => {
    await doFetch(ApiMethod.changeSetting, par);
    rootStore.user.fetchData();
  },

  declineSugg: async (par) => {
    await doFetch(ApiMethod.declineSugg, par);
    rootStore.suggs.fetchData();
  },

  addCardToQueue: async (par) => {
    await doFetch(ApiMethod.addCardToQueue, par);
    rootStore.suggs.fetchData();
    rootStore.currentStack.fetchData();
  },
};

export default mutate;
