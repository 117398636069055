import React, { FunctionComponent } from 'react';
import AddNewCard from './AddNewCard';
import Button from '../Primitives/Button/Button';
import rootStore from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
import Suggs from './Suggs';
import { File } from '../Primitives/Icons/Icons';
import { useTranslation } from 'react-i18next';

const AddCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, , stateValue] = rootStore.state.statePath;

  if (stateValue === 'addNewCard') {
    return <AddNewCard />;
  }

  return (
    <div className="w-full h-full p-4 flex flex-col">
      <Button
        dataTest="add-new-card"
        cb={() => {
          rootStore.state.goto(['app', 'addCard', 'addNewCard']);
        }}
      >
        add new card
      </Button>
      <div className="flex-grow relative">
        <Suggs />
      </div>
      <div className="flex justify-between">
        <div className="text-center font-mono uppercase">
          <File cardsCount={0} />
          {t('postponed')}
        </div>
        <div className="text-center font-mono uppercase">
          <File cardsCount={0} />
          {t('to learn')}
        </div>
      </div>
    </div>
  );
};

export default observer(AddCard);
