import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import rootStore from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';

const Counter: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2}>{t('repetitions')}</td>
        </tr>
        <tr>
          <td>{t('today')}</td>
          <td className="text-right">
            {rootStore.nextRept.data?.todayCount ?? 0}
          </td>
        </tr>
        <tr>
          <td>{t('tomorrow')}</td>
          <td className="text-right">
            {rootStore.currentStack.data?.nextDayReptCount}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default observer(Counter);
