interface IColl {
  collId: string;
  lang_from: string;
  lang_to: string;
  cardCount: number;
}

interface IUserStack extends IColl {
  currentDayNr: number;
  currentDayStartedOn: string | null; // currentDayStartedOn null => no schedule in this stack until now
  reptLearnCount: number;
  nextDayReptCount: number;
  addedOn: string;
  userId: string;
}

export type CollType = Readonly<IColl>;
export type StackType = Readonly<IUserStack>;
type CollRetType = Readonly<IColl>;

export type SettingsType = {
  reptsPerDay: number;
  inviId: string;
};

interface IUser {
  userId: string;
  settings: SettingsType;
  profile?: ProfileType;
  matesIds: string[];
}

export type UserType = Readonly<IUser>;
type UserRetType = IUser;

export type MeaningType = {
  meanId: string;
  cardId: string;
  meaning: string;
  example: string;
};

interface ICard {
  cardId: string;
  creatorId: string;
  word: string;
  meanings: MeaningTypeWithIds;
  collId: string;
}

export type CardType = Readonly<ICard>;
type CardRetType = ICard;

export type ReptType = {
  collId: string;
  userId: string;
  cardId: string;
  meaningId: string | null;
  dateNumber: number;
  review?: boolean;
  ease: number;
  history: ReptHistoryType[];
};

export type MeaningTypeWithIds = {
  [meaningId: string]: MeaningType;
};

export type ReptWithCardType = ReptType & {
  card: CardType;
};

export type StackCardStatusType = 'suggest' | 'decline' | 'queue' | 'learn';

export type StackCardType = {
  cardId: string;
  userId: string;
  collId: string;
  status: StackCardStatusType;
  suggestedFromUserId: string | null;
};

export type EntityType<T> = T & {
  entity: string;
  modified: string;
  created: string;
};

export type EntityTypes =
  | CardRetType
  | CollRetType
  | UserRetType
  | ReptType
  | AuthType
  | NoteType
  | StackType
  | StackCardType
  | InviType;

export enum ApiMethod {
  getCollectionAll = 1,
  getUser,
  addCard,
  addStack,
  doSchedule,
  increaseCurrentDayNr,
  updateProfile,
  getInvitation,
  acceptInvitation,
  getNotes,
  markNoteAsRead,
  getStack,
  getUserStacks,
  getNextRept,
  changeSetting,
  getSuggs,
  declineSugg,
  addCardToQueue,
}

export type NextReptType = {
  rept?: ReptWithCardType;
  todayCount: number;
};

export type ReptHistoryType = {
  createdOn: string; // xxxx-xx-xx
  result: boolean;
  day: number;
  ease: number;
};

export type AuthType = {
  authId: string;
  userId: string;
};

export type InviType = {
  inviId: string;
  issuerId: string;
  acceptorId?: string;
};

export type InviWithProfile = InviType & {
  userProfile: ShortProfile;
};

export type ProfileType = {
  nickname: string;
  name: string;
  picture: string;
  gender: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
};

export type ShortProfile = Pick<ProfileType, 'nickname' | 'name' | 'picture'>;

export type NoteType = {
  noteId: string;
  userId: string;
  read: boolean;
  header: string;
  picture: string;
  text: string;
};

export type SuggType = {
  userProfile: ShortProfile;
  suggs: CardType[];
  userId: string;
};

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];
type AuthIdAwarePars<P> = P & { authId: string };

export interface AuthIdAwareFunc<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends Record<string, any> = Record<string, any>,
  R = void
> {
  (par: AuthIdAwarePars<P>): Promise<R>;
}

export type ApiAuthIdOrUserIdInput = RequireOnlyOne<{
  userId: string;
  authId: string;
}>;

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export type APIType = Record<keyof typeof ApiMethod, AuthIdAwareFunc<any, any>>;
