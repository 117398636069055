import { CardType, ReptType } from '../database/model/EntityTypes';
import { config } from '../config/config';
import { ulid } from 'ulid';

function randnBm() {
  const u = 1 - Math.random(); // Subtraction to flip [0, 1) to (0, 1].
  const v = 1 - Math.random();
  return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
}

function randnCorrect(nmb: number) {
  const newNmb = Math.round(nmb + randnBm());
  return newNmb > 1 ? newNmb : 1;
}

// export const replaceElementById = <TD extends IdAware>(
//   ar: TD[],
//   replaceWith: TD
// ): NonEmptyArray<TD> => {
//   const index = ar.findIndex((i) => replaceWith.id === i.id);
//   if (index === -1) {
//     return runpointException('Element non found');
//   }
//   ar.splice(index, 1, replaceWith);
//   return ar as NonEmptyArray<TD>;
// };

export const removeElement = <TD>(
  ar: TD[] | undefined,
  predict: (item: TD) => boolean
): void => {
  if (typeof ar === 'undefined') {
    return;
  }

  const index = ar.findIndex(predict);
  if (index > -1) {
    ar.splice(index, 1);
  }
};

// export const arrayOfObjectToObject = <TD extends IdAware>(
//   arr: TD[]
// ): { [id: string]: TD } =>
//   arr.reduce(
//     (acc, it) => ({
//       ...acc,
//       [it.id]: it,
//     }),
//     {}
//   );

export const getSomePropValueFromObject = <TD>(o: Record<string, TD>): TD => {
  return o[Object.keys(o)[0]];
};

export const numberPadStart = (number: number) => {
  return number.toString().padStart(5, '0');
};

export const getRandomItem = <TD>(items?: TD[]): TD | undefined => {
  if (typeof items === 'undefined' || items.length === 0) {
    return undefined;
  }
  return items[Math.floor(Math.random() * items.length)];
};

export const getDate = (plusDay = 0): string => {
  const today = new Date();
  const anotherDay = new Date(today.valueOf());
  if (plusDay) {
    anotherDay.setDate(today.getDate() + plusDay);
  }

  return anotherDay.toISOString().slice(0, 10);
};

export const getNextRept = (
  rept: ReptType,
  reptResult: boolean,
  currentDayNr: number
): ReptType => {
  if (rept.review) {
    return {
      ...rept,
      review: !reptResult,
    };
  }

  let ease =
    rept.ease + (reptResult ? config.ease.stepPlus : config.ease.stepMinus);

  ease = Math.min(ease, config.ease.max);
  ease = Math.max(ease, config.ease.min);

  let dateNumber = currentDayNr + 1;
  if (rept.dateNumber > 0) {
    const lastInterval = currentDayNr - rept.history[0].day;
    dateNumber = currentDayNr + randnCorrect(lastInterval * rept.ease);
  }

  return {
    ...rept,
    review: rept.dateNumber === 0 ? false : !reptResult,
    dateNumber,
    ease,
    history: [
      {
        ease: rept.ease,
        day: currentDayNr,
        createdOn: getDate(),
        result: reptResult,
      },
      ...rept.history,
    ],
  };
};

export const getnanoid = (): string => {
  return ulid();
};

export const getInvitationUrl = (inviId: string): string => {
  return `${window.location.origin}/inv/${inviId}`;
};

export const cardToMeaningText = (card: CardType): string => {
  const meanIds = Object.keys(card.meanings);

  return meanIds
    .map(
      (meanId, ind) =>
        `${meanIds.length > 1 ? ind + 1 + '. ' : ''}${
          card.meanings[meanId].meaning
        }`
    )
    .join('; ');
};

export const joinWithDel = (ar: string[]): string => ar.join(config.delimiter);
