import React, { FunctionComponent, useEffect } from 'react';
import Card from '../Card/Card';
import SmallCard from '../SmallCard/SmallCard';
import { useTranslation } from 'react-i18next';
import { classnames } from 'tailwindcss-classnames';
import rootStore from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
import mutate from '../../store/mutate';
import LearningDashboard from './LearningDashboard/LearningDashboard';
import { when } from 'mobx';

const highlightOnTouchClassnames = classnames(
  'transition',
  'opacity-0',
  'hover:opacity-20'
);

function ClickLayer(props: { onClick: () => void; dataTest: string }) {
  return (
    <div
      data-test={props.dataTest}
      className={classnames(
        'absolute',
        'bg-blue-200',
        'w-full',
        'h-full',
        'top-0',
        'left-0',
        highlightOnTouchClassnames
      )}
      onClick={props.onClick}
    />
  );
}

const Learning: FunctionComponent = () => {
  const [, , stateValue] = rootStore.state.statePath;

  const rept = rootStore.rept;
  const { t } = useTranslation();

  useEffect(() => {
    rootStore.checkRetpPerDayLimit();
  }, [rept]);

  if (!rept) {
    return rootStore.nextRept.isLoading ? null : (
      <SmallCard
        type="warning"
        text={t('you have nothing to learn today. You cann add some new cards')}
      />
    );
  }

  let question: string;
  let answers: {
    answer: string;
    example: string;
    key: string;
  }[];

  if (!rept.meaningId) {
    question = rept.card.word;
    answers = Object.values(rept.card.meanings).map((mean) => ({
      answer: mean.meaning,
      example: mean.example,
      key: mean.meanId,
    }));
  } else {
    question = rept.card.meanings[rept.meaningId].meaning;
    answers = [
      {
        answer: rept.card.word,
        example: rept.card.meanings[rept.meaningId].example,
        key: rept.card.meanings[rept.meaningId].meanId,
      },
    ];
  }

  return (
    <div className="w-full h-full relative flex flex-col">
      <LearningDashboard />
      <div
        data-test="learning"
        className="grid gap-4 px-4 pb-4 auto-rows-fr flex-grow relative"
      >
        <Card main={question} dataTest="question" />
        {answers.map((a) => (
          <Card
            invisible={stateValue === 'question'}
            key={a.key}
            main={a.answer}
            small={a.example}
          />
        ))}
        {stateValue === 'question' && (
          <ClickLayer
            dataTest="clickLayer"
            onClick={() => {
              rootStore.state.goto(['app', 'learning', 'answers']);
            }}
          />
        )}
        {stateValue === 'answers' && (
          <div className="absolute w-full h-full top-0 left-0 flex">
            <div
              data-test="clickLayerLeft"
              className={classnames(
                'flex-grow',
                'transition',
                'bg-red-200',
                highlightOnTouchClassnames
              )}
              onClick={() => {
                rootStore.state.goto(['app', 'learning', 'revision']);
                mutate.doSchedule({
                  rept,
                  reptResult: false,
                });
              }}
            />
            <div
              data-test="clickLayerRight"
              className={classnames(
                'flex-grow',
                'transition',
                'bg-green-200',
                highlightOnTouchClassnames
              )}
              onClick={() => {
                rootStore.state.goto(['app', 'learning', 'revision']);
                mutate.doSchedule({
                  rept,
                  reptResult: true,
                });
              }}
            />
          </div>
        )}
        {stateValue === 'revision' && (
          <ClickLayer
            dataTest="clickLayer"
            onClick={async () => {
              await when(() => !rootStore.nextRept.isLoading);
              rootStore.removeRept();
              rootStore.state.goto(['app', 'learning', 'question']);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Learning);
