import { StateStore } from './stateStore';
import { autorun, makeAutoObservable, reaction, runInAction, when } from 'mobx';
import { config } from '../config/config';
import { DataFetcherStore } from './DataFetcherStore';
import {
  ApiMethod,
  CollType,
  InviWithProfile,
  NextReptType,
  NoteType,
  ProfileType,
  ReptWithCardType,
  RequireOnlyOne,
  StackType,
  SuggType,
  UserType,
} from '../database/model/EntityTypes';
import { getDate } from '../utils/utils';
import mutate from './mutate';
import AwareReptPerDayLimit from 'src/components/Modal/AwareReptPerDayLimit';
import React from 'react';
import AcceptInvitation from '../components/Modal/AcceptInvitation';

type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

type ParamCollId = {
  collId: string;
};

type DeepLinkParams = {
  inviId: string;
};

export class RootStore {
  modal?: JSX.Element;
  notes: NoteType[] = [];
  state: StateStore;
  user: DataFetcherStore<UserType>;
  userStacks: DataFetcherStore<StackType[]>;
  suggs: DataFetcherStore<SuggType[], ParamCollId>;
  colls: DataFetcherStore<CollType[]>;
  currentStack: DataFetcherStore<StackType, ParamCollId>;
  nextRept: DataFetcherStore<NextReptType, ParamCollId>;
  ignoreReptPerDayLimit = false;
  private $collId?: string;
  private $rept?: ReptWithCardType;
  deeplinkParams?: RequireOnlyOne<DeepLinkParams>;

  constructor(initial: PureStateData, fromLocalStorage: boolean) {
    this.deeplinkParams = initial.deeplinkParams;
    this.state = new StateStore(initial.state.statePath);
    this.user = new DataFetcherStore<UserType>(
      ApiMethod.getUser,
      fromLocalStorage,
      initial.user,
      undefined,
      () => {
        // if (!this.currentStack.data) {
        //   this.collId = undefined;
        // }
        if (this.deeplinkParams?.inviId) {
          mutate.getInvitation({
            inviId: this.deeplinkParams?.inviId,
          });
        }
      }
    );
    this.userStacks = new DataFetcherStore<StackType[]>(
      ApiMethod.getUserStacks,
      fromLocalStorage,
      initial.userStacks
    );
    this.$collId = initial.collId;
    this.colls = new DataFetcherStore<CollType[]>(
      ApiMethod.getCollectionAll,
      fromLocalStorage,
      initial.colls
    );
    makeAutoObservable(this);
    this.currentStack = new DataFetcherStore<StackType, ParamCollId>(
      ApiMethod.getStack,
      fromLocalStorage,
      initial.currentStack,
      () => {
        return this.collId ? { collId: this.collId } : undefined;
      }
    );

    this.suggs = new DataFetcherStore<SuggType[], ParamCollId>(
      ApiMethod.getSuggs,
      fromLocalStorage,
      initial.suggs,
      () => {
        return this.collId ? { collId: this.collId } : undefined;
      }
    );

    this.nextRept = new DataFetcherStore<NextReptType, ParamCollId>(
      ApiMethod.getNextRept,
      fromLocalStorage,
      initial.nextRept,
      () => {
        return this.currentStack.$data
          ? { collId: this.currentStack.$data.collId }
          : undefined;
      },
      () => {
        console.log();
        if (
          (!this.nextRept.data?.rept ||
            this.nextRept.data?.rept.dateNumber === 0) &&
          this.currentStack.data?.currentDayStartedOn &&
          this.currentStack.data?.currentDayStartedOn < getDate() &&
          this.collId
        ) {
          mutate.increaseCurrentDayNr({
            collId: this.collId,
          });
        }
      }
    );

    this.parseDeepLink();

    autorun(() => {
      const snapshot: PureStateData = this.toJSON();
      localStorage.setItem(config.sankiStorageKey, JSON.stringify(snapshot));
    });

    when(
      () => !!this.user.$data,
      () => {
        mutate.getNotes({});
      }
    );
  }

  private parseDeepLink() {
    const match = window.location.pathname.match(/^\/([a-z]+)\/(\w+)$/);
    if (match && match[1] === 'inv') {
      this.deeplinkParams = {
        inviId: match[2],
      };
      window.location.replace(window.location.origin);
    }

    if (window.location.pathname && window.location.pathname !== '/') {
      window.location.replace(window.location.origin);
    }
  }

  get collId(): string | undefined {
    return this.$collId;
  }

  set collId(collId: string | undefined) {
    if (!collId) {
      console.log('alarm setting collId to undefined');
    }
    this.$collId = collId;
    if (this.$collId) {
      this.state.goto(['app', 'learning']);
    }
  }

  removeRept(): void {
    this.$rept = undefined;
  }

  set rept(r) {
    this.$rept = r;
  }

  get rept(): ReptWithCardType | undefined {
    if (this.$rept) {
      return this.$rept;
    }

    const rept = this.nextRept.data?.rept;

    runInAction(() => {
      if (rept) {
        this.$rept = rept;
      }
    });

    return this.$rept;
  }

  closeModal(): void {
    this.modal = undefined;
  }

  checkRetpPerDayLimit(): void {
    if (this.ignoreReptPerDayLimit) {
      return;
    }

    if (this.rept?.dateNumber === 0) {
      const { reptsPerDay } =
        this.user.data?.settings ?? config.defaultSettings;
      if ((this.currentStack.data?.nextDayReptCount ?? 0) >= reptsPerDay) {
        this.modal = React.createElement(AwareReptPerDayLimit);
      }
    }
  }

  get learnCountForToday(): number {
    return (
      (this.currentStack.data?.reptLearnCount ?? 0) +
      (this.nextRept.data?.todayCount ?? 0)
    );
  }

  updateProfile(profile: ProfileType): void {
    const dispose = reaction(
      () => this.user.$data,
      () => {
        if (!this.user.data?.profile) {
          mutate.updateProfile({ profile });
          dispose();
        }
      }
    );
  }

  showInvitation(invi: InviWithProfile): void {
    this.deeplinkParams = undefined;
    this.modal = React.createElement(AcceptInvitation, {
      invi,
    });
  }

  addNotes(notes: NoteType[]): void {
    notes.forEach((note) => {
      if (!this.notes.find((n) => n.noteId === note.noteId)) {
        this.notes.push(note);
      }
    });
  }

  closeNote(noteId: string): void {
    const ind = this.notes.findIndex((note) => note.noteId === noteId);
    this.notes.splice(ind, 1);
    mutate.markNoteAsRead({ noteId });
  }

  private toJSON(): PureStateData {
    return {
      collId: this.$collId,
      state: { ...this.state },
      user: this.user.$data,
      userStacks: this.userStacks.$data,
      colls: this.colls.$data,
      currentStack: this.currentStack.$data,
      deeplinkParams: this.deeplinkParams,
    };
  }
}

type PureStateData = {
  state: NonFunctionProperties<StateStore>;
  user?: UserType;
  userStacks?: StackType[];
  colls?: CollType[];
  nextRept?: NextReptType;
  collId?: string;
  currentStack?: StackType;
  suggs?: SuggType[];
  deeplinkParams?: DeepLinkParams;
};

const initial: PureStateData = {
  state: {
    statePath: [],
  },
};

const localValue = localStorage.getItem(config.sankiStorageKey);
const persistedState = localValue ? JSON.parse(localValue) : initial;

export default new RootStore(persistedState, !!localValue);
