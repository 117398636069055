import { classnames } from 'tailwindcss-classnames';

export const smallText = classnames('text-sm', 'text-gray-600');
export const label = classnames(smallText, 'leading-7');
export const badge = classnames(
  'rounded-full',
  'bg-blue-600',
  'text-white',
  'p-1',
  'rounded',
  'leading-none',
  'flex',
  'items-center',
  'text-xs'
);

export const button = classnames(
  'w-full',
  'inline-flex',
  'justify-center',
  'rounded-md',
  'border',
  'shadow-sm',
  'px-4',
  'py-2',
  'text-base',
  'font-medium',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'sm:ml-3',
  'sm:w-auto',
  'sm:text-sm'
);

export const buttonDanger = classnames(
  button,
  'border-transparent',
  'bg-red-600',
  'text-white',
  'hover:bg-red-700',
  'focus:ring-red-500'
);

export const buttonCancel = classnames(
  button,
  'mt-3',
  'border-gray-300',
  'bg-white',
  'text-gray-700',
  'hover:bg-gray-50',
  'focus:ring-indigo-500',
  'sm:mt-0'
);
