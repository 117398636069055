import { autorun, makeAutoObservable, runInAction } from 'mobx';

interface PromiseResolverFunc<TResolves> {
  (): TResolves | undefined | Promise<TResolves>;
}

export class PromiseResolver<TResolves> {
  private result?: TResolves;
  promise: Promise<TResolves>;
  // eslint-disable-next-line no-unused-vars
  private resolve?: (value: TResolves) => void;

  constructor(resolver?: PromiseResolverFunc<TResolves>) {
    this.promise = new Promise<TResolves>((resolve) => {
      this.resolve = resolve;
    });

    if (resolver) {
      this.setResolver(resolver);
    }

    makeAutoObservable(this);
    autorun(() => {
      if (this.result) {
        this.resolve?.(this.result);
      }
    });
  }

  setResolver(func: PromiseResolverFunc<TResolves>): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    autorun(() => {
      Promise.resolve(func()).then((result) => {
        runInAction(() => {
          self.result = result;
        });
      });
    });
  }
}
