import React, { FunctionComponent } from 'react';
import IconButton from '../../Primitives/IconButton/IconButton';
import { Plus } from '../../Primitives/Icons/Icons';
import rootStore from '../../../store/rootStore';

const PlusButton: FunctionComponent = () => {
  return (
    <IconButton
      onClick={() => {
        rootStore.state.goto(['app', 'addCard']);
      }}
      Icon={Plus}
      dataTest="add-card"
    />
  );
};

export default PlusButton;
