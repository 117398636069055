import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import IntroLayout from '../Intro/IntroLayout/IntroLayout';
import Login from '../App/Login';
import AppDispatcher from './AppDispatcher';
import rootStore from '../../store/rootStore';

const Dispatcher: FunctionComponent = () => {
  const [state] = rootStore.state.statePath;

  return (
    <>
      {state === 'intro' && <IntroLayout />}
      {state === 'login' && <Login />}
      {state === 'app' && <AppDispatcher />}
    </>
  );
};

export default observer(Dispatcher);
