import React, { FunctionComponent } from 'react';

interface OwnProps {
  Icon: FunctionComponent;
  onClick?: () => void;
  dataTest: string;
  addInfo?: JSX.Element;
}

type Props = OwnProps;

const IconButton: FunctionComponent<Props> = ({
  Icon,
  onClick,
  dataTest,
  addInfo,
}) => (
  <button
    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75"
    onClick={onClick}
    data-test={dataTest}
    type="button"
  >
    <Icon />
    {addInfo && <div className="absolute top-0 right-0">{addInfo}</div>}
  </button>
);

export default IconButton;
