import React, { FunctionComponent, useState } from 'react';
import FormElement from '../AddCard/FormElement/FormElement';
import { useTranslation } from 'react-i18next';
import rootStore from '../../store/rootStore';
import { getInvitationUrl } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import { useDebouncedCallback } from 'use-debounce';
import mutate from '../../store/mutate';
import { config } from '../../config/config';
import InviUrl from './InviUrl';

const Settings: FunctionComponent = () => {
  const [reptsPerDay, setReptsPerDay] = useState(
    rootStore.user.data?.settings.reptsPerDay
  );
  const { t } = useTranslation();

  const counter = `${t(
    'optimal count of repetitions per day'
  )}: ${reptsPerDay}`;

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      mutate.changeSetting({
        settingName: 'reptsPerDay',
        settingValue: value,
      });
    },
    // delay in ms
    config.debounce
  );

  const invUrl = getInvitationUrl(
    rootStore.user.data?.settings.inviId ?? 'test'
  );

  return (
    <div className="p-2">
      <FormElement header={counter}>
        <input
          type="range"
          min="10"
          max="100"
          step="5"
          defaultValue={reptsPerDay}
          onChange={(e) => {
            const x = parseInt(e.target.value, 10);
            setReptsPerDay(x);
            debounced(x);
          }}
        />
      </FormElement>
      <FormElement
        header={t(
          'use this link to invite somebody to sanki to learn cards with you'
        )}
      >
        <InviUrl value={invUrl} />
      </FormElement>
    </div>
  );
};

export default observer(Settings);
