import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormElement from './FormElement/FormElement';
import Input from '../Primitives/Input/Input';
import { CardType, MeaningType } from '../../database/model/EntityTypes';
import { getnanoid, getSomePropValueFromObject } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import rootStore from '../../store/rootStore';
import mutate from '../../store/mutate';
import Button from '../Primitives/Button/Button';

const createMeaning = (cardId: string) => {
  const meaning: MeaningType = {
    meanId: getnanoid(),
    cardId,
    meaning: '',
    example: '',
  };

  return meaning;
};

const canNotBeSubmitted = (
  card: Omit<CardType, 'creatorId'>
): true | undefined => {
  const check =
    !!card.word && !!getSomePropValueFromObject(card.meanings).meaning;

  return check ? undefined : true;
};

const initialCard = () => {
  const id = getnanoid();
  const meaning = createMeaning(id);

  const card1: Omit<CardType, 'creatorId'> = {
    cardId: id,
    word: '',
    meanings: {
      [meaning.meanId]: meaning,
    },
    collId: '',
  };

  return card1;
};

const AddNewCard: FunctionComponent = () => {
  const stack = rootStore.currentStack.data;
  const { t } = useTranslation();

  const [card, setCard] = useState<Omit<CardType, 'creatorId'>>(initialCard);

  if (!stack) {
    return null;
  }

  const { word, meanings } = card;

  return (
    <form data-test="edit-card-form" className="p-4">
      <FormElement>
        <Input
          idName="word"
          label={`${t('Word or phrase')} (${t(stack.lang_to)})`}
          value={word}
          onChange={(newValue) => {
            setCard({
              ...card,
              word: newValue,
            });
          }}
        />
      </FormElement>
      <FormElement header={t('Variants')}>
        <ol>
          {Object.values(meanings).map((m, key) => {
            return (
              <li key={m.meanId}>
                <Input
                  idName={`${m.meanId}meaning`}
                  placeholder={t('Meaning')}
                  value={m.meaning}
                  dataTest={`meaning_${key}`}
                  onChange={(newValue) => {
                    const newMeaning: MeaningType = {
                      ...m,
                      meaning: newValue,
                    };

                    const newMeanings = {
                      ...card.meanings,
                      [newMeaning.meanId]: newMeaning,
                    };

                    setCard({
                      ...card,
                      meanings: newMeanings,
                    });
                  }}
                />
                <div className="mt-4">
                  <Input
                    type="textarea"
                    idName={`${m.meanId}example`}
                    placeholder={`${t('Example')} (${t('optional')}, ${t(
                      stack.lang_to
                    )})`}
                    value={m.example}
                    onChange={(newValue) => {
                      const newMeaning: MeaningType = {
                        ...m,
                        example: newValue,
                      };

                      const newMeanings = {
                        ...card.meanings,
                        [newMeaning.meanId]: newMeaning,
                      };

                      setCard({
                        ...card,
                        meanings: newMeanings,
                      });
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ol>
      </FormElement>
      <Button
        dataTest="doAddCard"
        cb={async () => {
          await mutate.addCard({
            card: {
              ...card,
              collId: stack?.collId,
            },
          });
          setCard(initialCard());
        }}
        disabled={canNotBeSubmitted(card)}
      >
        {t('ready')}
      </Button>
    </form>
  );
};

export default observer(AddNewCard);
