import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import 'swiper/swiper-bundle.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Slide } from './Slide/Slide';
import Button from '../Primitives/Button/Button';
import { observer } from 'mobx-react-lite';
import rootStore from '../../store/rootStore';

SwiperCore.use([Pagination]);

export const Intro = observer(() => {
  const { t } = useTranslation();

  return (
    <div data-test="intro" className="h-full">
      <Swiper
        className="h-full"
        slidesPerView="auto"
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <Slide>Slide 1</Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>Slide 2</Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>Slide 3</Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>Slide 4</Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <Button
              cb={() => {
                rootStore.state.goto(['login']);
              }}
            >
              {t('go to app')}
            </Button>
          </Slide>
        </SwiperSlide>
      </Swiper>
    </div>
  );
});
