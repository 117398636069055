import React, { FunctionComponent } from 'react';
import IconButton from '../../Primitives/IconButton/IconButton';
import { Setting } from '../../Primitives/Icons/Icons';
import rootStore from '../../../store/rootStore';

const SettingButton: FunctionComponent = () => {
  return (
    <IconButton
      onClick={() => {
        rootStore.state.goto(['app', 'settings']);
      }}
      Icon={Setting}
      dataTest="settings"
    />
  );
};

export default SettingButton;
