import React, { FunctionComponent } from 'react';
import { NoteType } from '../../database/model/EntityTypes';
import { smallText } from '../../twStyles/twStyles';
import { Close } from '../Primitives/Icons/Icons';
import IconButton from '../Primitives/IconButton/IconButton';
import rootStore from '../../store/rootStore';

interface OwnProps {
  note: NoteType;
}

type Props = OwnProps;

const Note: FunctionComponent<Props> = ({ note }) => {
  return (
    <div className="flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="w-2 bg-gray-800"></div>
      <div className="flex items-center px-2 py-3">
        <img
          alt="pct"
          className="w-12 h-12 object-cover rounded-full"
          src={note.picture}
        />
        <div className="mx-3">
          <h2 className="text-xl font-semibold text-gray-800">{note.header}</h2>
          <p className={smallText}>{note.text}</p>
        </div>
        <IconButton
          Icon={Close}
          dataTest="close"
          onClick={() => {
            rootStore.closeNote(note.noteId);
          }}
        />
      </div>
    </div>
  );
};

export default Note;
