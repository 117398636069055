import React, { FunctionComponent } from 'react';
import IconButton from '../../../Primitives/IconButton/IconButton';
import { Stack } from '../../../Primitives/Icons/Icons';
import rootStore from '../../../../store/rootStore';

const SelectStackButton: FunctionComponent = () => {
  return (
    <IconButton
      onClick={() => {
        rootStore.state.goto(['app', 'selectStack']);
      }}
      Icon={Stack}
      dataTest="select-stack"
    />
  );
};

export default SelectStackButton;
