import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import rootStore from '../../store/rootStore';
import UserSuggs from './UserSuggs';

const Suggs: FunctionComponent = () => {
  const suggs = rootStore.suggs.data;

  if (!suggs) {
    return null;
  }

  return (
    <>
      {suggs.map((sugg) => (
        <UserSuggs key={sugg.userId} sugg={sugg} />
      ))}
    </>
  );
};

export default observer(Suggs);
