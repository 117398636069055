import React, { FunctionComponent } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import Button from '../Primitives/Button/Button';
import { Center } from '../Primitives/Center/Center';
import rootStore from '../../store/rootStore';

const Login: FunctionComponent = () => {
  //  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      dataTest="logout"
      cb={async () => {
        //        await loginWithRedirect();
        rootStore.state.goto(['app']);
      }}
    >
      Login
    </Button>
  );
};

export default function LoginWithLayout(): JSX.Element {
  return (
    <Center>
      <Login />
    </Center>
  );
}
