import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import '@github/clipboard-copy-element';
import Input from '../Primitives/Input/Input';
import IconButton from '../Primitives/IconButton/IconButton';
import { Copy } from '../Primitives/Icons/Icons';
import { smallText } from '../../twStyles/twStyles';

interface OwnProps {
  value: string;
}

type Props = OwnProps;

const InviUrl: FunctionComponent<Props> = ({ value }) => {
  const [isCopied, setCopied] = useState(false);

  const handleCopied = useCallback(() => {
    setCopied(true);
  }, []);

  useEffect(() => {
    window.addEventListener('clipboard-copy', handleCopied);

    return () => {
      window.removeEventListener('clipboard-copy', handleCopied);
    };
  }, [handleCopied]);

  return (
    <div className="flex items-center">
      <Input idName="invUrl" value={value} disabled={true} />
      <clipboard-copy value={value}>
        <div className="relative">
          <IconButton Icon={Copy} dataTest="copy" />
          <div
            hidden={!isCopied}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45"
          >
            <span className={smallText}>
              <span className="text-red-500">copied</span>
            </span>
          </div>
        </div>
      </clipboard-copy>
    </div>
  );
};

export default InviUrl;
