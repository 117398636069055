import { makeAutoObservable, runInAction } from 'mobx';
import { ApiMethod } from '../database/model/EntityTypes';
import { PromiseResolver } from './PromiseResolver';
import { doFetch } from './fetch';

export type RecordOrUndefined = Record<string, unknown> | undefined;

export class DataFetcherStore<
  TData,
  TParams extends RecordOrUndefined = undefined
> {
  $data?: TData;
  private readonly mtd: ApiMethod;
  isLoading = false;
  parameterResolver?: PromiseResolver<TParams>;
  postFetch?: () => void;

  constructor(
    mtd: ApiMethod,
    refetch: boolean,
    initialData?: TData,
    paramResolver?: () => TParams | undefined,
    postFetch?: () => void
  ) {
    this.$data = initialData;
    this.mtd = mtd;
    if (paramResolver) {
      this.parameterResolver = new PromiseResolver<TParams>(paramResolver);
    }
    if (refetch && this.$data) {
      this.fetchData();
    }
    makeAutoObservable(this, {
      isLoading: false,
      fetchData: false,
    });
    this.postFetch = postFetch;
  }

  get data(): TData | undefined {
    if (!this.$data) {
      this.fetchData();
    }
    return this.$data;
  }

  async fetchData(): Promise<undefined> {
    if (this.isLoading) {
      return undefined;
    }

    runInAction(() => {
      this.isLoading = true;
    });

    const responseBody = await doFetch<TData, TParams>(
      this.mtd,
      this.parameterResolver?.promise
    );

    runInAction(() => {
      this.$data = responseBody;
      this.isLoading = false;
    });

    if (this.postFetch) {
      this.postFetch();
    }

    return undefined;
  }
}
