import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import AddStack from './AddStack/AddStack';
import Loader from '../Primitives/Loader/Loader';
import SmallCard from '../SmallCard/SmallCard';
import Stack from './Stack/Stack';
import rootStore from '../../store/rootStore';
import { observer } from 'mobx-react-lite';

const SelectStack: FunctionComponent = () => {
  const user = rootStore.user.data;
  const { t } = useTranslation();

  if (!user) {
    return <Loader />;
  }

  const usersStacks = rootStore.userStacks.data;

  return (
    <div data-test="select-stack">
      {!usersStacks || usersStacks.length === 0 ? (
        <SmallCard
          dataTest="no_coll"
          type="warning"
          text={t('you have no card collection yet')}
        />
      ) : (
        usersStacks.map((stack) => (
          <div
            key={stack.collId}
            onClick={() => {
              rootStore.collId = stack.collId;
            }}
          >
            <Stack coll={stack} />
          </div>
        ))
      )}
      <AddStack />
    </div>
  );
};

export default observer(SelectStack);
