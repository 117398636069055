import React, { FunctionComponent } from 'react';
import IconButton from '../../Primitives/IconButton/IconButton';
import { Learn } from '../../Primitives/Icons/Icons';
import { badge } from '../../../twStyles/twStyles';
import rootStore from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';

const LearnButton: FunctionComponent = () => {
  const count = rootStore.learnCountForToday;

  return (
    <div className="relative">
      <IconButton
        onClick={() => {
          rootStore.state.goto(['app', 'learning']);
        }}
        Icon={Learn}
        dataTest="learn"
        addInfo={
          rootStore.currentStack.data ? (
            <div data-test="learnCount" className={badge}>
              {count}
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export default observer(LearnButton);
