import { animated, interpolate, useSprings } from '@react-spring/web';
import React, { FunctionComponent, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import { CardType } from '../../database/model/EntityTypes';
import Card from '../Card/Card';
import { cardToMeaningText } from '../../utils/utils';

interface OwnProps {
  cards: CardType[];
  onSwipeLeft: (card: CardType) => void;
  onSwipeRight: (card: CardType) => void;
}

type Props = OwnProps;
const CARDS_IN_STACK = 10;

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

const CardStack: FunctionComponent<Props> = ({
  cards,
  onSwipeLeft,
  onSwipeRight,
}) => {
  const [offset, setOffset] = useState(0);
  const cardsToShow = cards.slice(offset, offset + CARDS_IN_STACK);

  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out

  const [props, set] = useSprings(cardsToShow.length, (i) => ({
    ...to(i),
    from: from(),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({
      args: [index],
      down,
      movement: [mx, my],
      direction: [xDir],
      velocity,
    }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) {
        // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        gone.add(index);
        if (dir === -1) {
          onSwipeLeft(cardsToShow[index]);
        } else {
          onSwipeRight(cardsToShow[index]);
        }
      }
      set((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? 200 * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const y = isGone ? 200 : down ? my : 0;
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = isGone ? 0 : down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          y,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === CARDS_IN_STACK) {
        setTimeout(() => {
          gone.clear();
          setOffset(offset + CARDS_IN_STACK);
          set((i) => ({
            ...to(i),
            reset: true,
          }));
        }, 600);
      }
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div
          key={i}
          style={{
            x,
            y,
            touchAction: 'none',
          }}
          className="absolute"
        >
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
            }}
          >
            <div className="w-10/12 ml-6">
              <Card
                dataTest="card-preview"
                main={cardsToShow[i].word}
                small={cardToMeaningText(cardsToShow[i])}
              />
            </div>
          </animated.div>
        </animated.div>
      ))}
    </>
  );
};

export default CardStack;
