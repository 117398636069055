import React, { FunctionComponent, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import SelectStack from '../SelectStack/SelectStack';
import AppLayout from '../App/AppLayout';
import Learning from '../Learning/Learning';
import Loader from '../Primitives/Loader/Loader';
import rootStore from '../../store/rootStore';
import { setAccesTokenPromise } from '../../store/fetch';
import Settings from '../Settings/Settings';
import AddCard from '../AddCard/AddCard';
import { animated, useTransition } from '@react-spring/web';

const AppDispatcher: FunctionComponent = () => {
  const [, appState] = rootStore.state.statePath;
  const { getAccessTokenSilently, user } = useAuth0();

  setAccesTokenPromise(getAccessTokenSilently);
  useEffect(() => {
    rootStore.updateProfile(user);
  }, []);

  const transitions = useTransition(appState, {
    from: {
      opacity: 0,
      //      transform: 'translate3d(100%,0,0)'
    },
    enter: {
      opacity: 1,
      //      transform: 'translate3d(0%,0,0)'
    },
    // leave: {
    //   opacity: 0,
    //   //      transform: 'translate3d(-50%,0,0)'
    // },
  });

  return (
    <>
      {transitions((props, key) => (
        <AppLayout>
          <animated.div key={key} style={props} className="w-full h-full">
            {key === 'selectStack' && <SelectStack />}
            {key === 'addCard' && <AddCard />}
            {key === 'learning' && <Learning />}
            {key === 'settings' && <Settings />}
          </animated.div>
        </AppLayout>
      ))}
    </>
  );
};

const onRedirecting = () => <Loader />;

export default withAuthenticationRequired(AppDispatcher, {
  onRedirecting,
});
