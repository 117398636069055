import { PromiseResolver } from './PromiseResolver';
import config from '../config/aws.json';
import { ApiMethod } from '../database/model/EntityTypes';
import { RecordOrUndefined } from './DataFetcherStore';

const accessTokenResolver = new PromiseResolver<string>();

export const setAccesTokenPromise = (
  funcReturnsPromise: () => Promise<string>
): void => {
  accessTokenResolver.setResolver(funcReturnsPromise);
};

export const doFetch = async <
  TData = undefined,
  TParams extends RecordOrUndefined = undefined
>(
  mtd: ApiMethod,
  pars?: TParams | Promise<TParams>
): Promise<TData | undefined> => {
  const accessToken = await accessTokenResolver.promise;

  const params = await pars;

  const body = params ?? {};

  const method = process.env.NODE_ENV === 'development' ? ApiMethod[mtd] : mtd;

  const response = await fetch(`${config.ServiceEndpoint}/act?m=${method}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  let ret: Promise<TData | undefined>;

  try {
    ret = await response.json();
  } catch (e) {
    ret = Promise.resolve(undefined);
  }

  return ret;
};
