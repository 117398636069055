import React, { FunctionComponent } from 'react';

interface OwnProps {
  dataTest?: string;
  cb?: () => void;
  disabled?: true;
}

type Props = OwnProps;

const Button: FunctionComponent<Props> = ({
  children,
  dataTest,
  disabled,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cb = () => {},
}) => (
  <button
    disabled={disabled}
    data-test={dataTest}
    onClick={() => {
      cb();
    }}
    type="button"
    className="disabled:opacity-50 inline-flex items-center border shadow-md border-indigo-500 bg-indigo-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
  >
    {children}
  </button>
);
export default Button;
