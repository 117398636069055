import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CollType } from '../../../database/model/EntityTypes';
import SmallCard from '../../SmallCard/SmallCard';

interface OwnProps {
  coll: CollType;
}

type Props = OwnProps;

const Stack: FunctionComponent<Props> = ({ coll }) => {
  const { t } = useTranslation();
  return (
    <SmallCard
      dataTest={coll.collId}
      text={`${t('main language')}: ${coll.lang_from}`}
      type="custom"
      header={`${t('learning words')}: ${coll.lang_to}`}
    />
  );
};

export default Stack;
