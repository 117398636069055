import React, { ChangeEvent, FunctionComponent } from 'react';
import * as styles from 'src/twStyles/twStyles';

interface OwnProps {
  idName: string;
  type?: string;
  label?: string;
  placeholder?: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (newValue: string) => void;
  dataTest?: string;
  disabled?: boolean;
}

type Props = OwnProps;

const Input: FunctionComponent<Props> = ({
  idName,
  type = 'text',
  label,
  placeholder = label,
  value,
  onChange,
  dataTest,
  disabled = false,
}) => {
  const props = {
    value,
    onChange: (e: ChangeEvent) => {
      // @ts-ignore
      onChange && onChange(e.target.value);
    },
    type,
    id: idName,
    name: idName,
    'data-test': dataTest,
    placeholder,
    disabled,
    className:
      'w-full px-4 py-2 text-black transition duration-500 ease-in-out transform bg-gray-100 border-transparent rounded-lg mr-4text-base focus:border-gray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2',
  };

  return (
    <>
      {label && (
        <label htmlFor={idName} className={styles.label}>
          {label}
        </label>
      )}
      {type === 'textarea' && <textarea {...props} />}
      {type !== 'textarea' && <input {...props} />}
    </>
  );
};

export default Input;
