import React, { FunctionComponent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import IconButton from '../../../Primitives/IconButton/IconButton';
import { Logout } from '../../../Primitives/Icons/Icons';
import rootStore from '../../../../store/rootStore';

const LogoutButton: FunctionComponent = () => {
  const { logout } = useAuth0();

  return (
    <IconButton
      Icon={Logout}
      // @ts-ignore
      onClick={() => {
        logout({ returnTo: window.location.origin });
        rootStore.state.goto(['login']);
      }}
      dataTest="logout"
    />
  );
};

export default LogoutButton;
