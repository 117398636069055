import React, { FunctionComponent, Suspense } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import Loader from './components/Primitives/Loader/Loader';
import Dispatcher from './components/Dispatcher/Dispatcher';

const App: FunctionComponent = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
    useRefreshTokens
  >
    <Dispatcher />
  </Auth0Provider>
);

export default function AppWithSuspense(): JSX.Element {
  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  );
}
