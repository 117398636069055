import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import rootStore from '../../store/rootStore';

const ModalEngine: FunctionComponent = () => {
  if (!rootStore.modal) {
    return null;
  }

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center sanki-min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/*Background overlay, show/hide based on modal state.*/}

        {/*Entering: "ease-out duration-300"*/}
        {/*  From: "opacity-0"*/}
        {/*  To: "opacity-100"*/}
        {/*Leaving: "ease-in duration-200"*/}
        {/*  From: "opacity-100"*/}
        {/*  To: "opacity-0"*/}
        <div
          onClick={() => rootStore.closeModal()}
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        {/*This element is to trick the browser into centering the modal contents.*/}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        {/*Modal panel, show/hide based on modal state.*/}

        {/*Entering: "ease-out duration-300"*/}
        {/*  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
        {/*  To: "opacity-100 translate-y-0 sm:scale-100"*/}
        {/*Leaving: "ease-in duration-200"*/}
        {/*  From: "opacity-100 translate-y-0 sm:scale-100"*/}
        {/*  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          {rootStore.modal}
        </div>
      </div>
    </div>
  );
};

export default observer(ModalEngine);
