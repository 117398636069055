export const config = {
  debounce: 1000, // debouncing for inputs, ms
  delimiter: '#',
  defaultEase: 2,
  ease: {
    stepPlus: 0.2,
    stepMinus: -0.2,
    max: 2.5,
    min: 1.3,
  },
  sankiStorageKey: 'sanki-v1',
  defaultSettings: {
    reptsPerDay: 50,
  },
};
