import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import rootStore from '../../store/rootStore';
import Note from './Note';

const Notes: FunctionComponent = () => {
  if (rootStore.notes.length === 0) {
    return null;
  }

  return (
    <div data-test="notifications" className="absolute">
      {rootStore.notes.map((note) => (
        <Note key={note.noteId} note={note} />
      ))}
    </div>
  );
};

export default observer(Notes);
