import React, { FunctionComponent } from 'react';
import ModalPanel from './ModalPanel';
import { useTranslation } from 'react-i18next';
import rootStore from '../../store/rootStore';
import { config } from '../../config/config';
import {
  button,
  buttonCancel,
  buttonDanger,
  smallText,
} from '../../twStyles/twStyles';
import { runInAction } from 'mobx';
const AwareReptPerDayLimit: FunctionComponent = () => {
  const { t } = useTranslation();

  const { reptsPerDay } =
    rootStore.user.data?.settings ?? config.defaultSettings;

  const buttons = (
    <>
      <button
        type="button"
        className={buttonDanger}
        onClick={() => {
          rootStore.state.goto(['app', 'settings']);
          rootStore.closeModal();
        }}
      >
        {t('Settings')}
      </button>
      <button
        type="button"
        className={buttonCancel}
        onClick={() => {
          runInAction(() => {
            rootStore.ignoreReptPerDayLimit = true;
            rootStore.closeModal();
          });
        }}
      >
        {t('Ignore')}
      </button>
    </>
  );

  return (
    <ModalPanel buttons={buttons}>
      <p className={smallText}>
        {t(
          'Repetition count for tomorrow has already exceed limit of {{reptsPerDay}}. You can adjust this settings or just ignore this message for current session.',
          {
            reptsPerDay,
          }
        )}
      </p>
    </ModalPanel>
  );
};

export default AwareReptPerDayLimit;
