import React, { FunctionComponent } from 'react';
import LogoutButton from './LogoutButton/LogoutButton';
import SelectStackButton from './SelectStackButton/SelectStackButton';
import PlusButton from './PlusButton';
import LearnButton from './LearnButton';
import SettingButton from './SettingButton';

const AppFooter: FunctionComponent = () => (
  <ul className="flex flex-row list-none ml-auto bg-gray-200 justify-around">
    <li className="nav-item">
      <LearnButton />
    </li>
    <li className="nav-item">
      <PlusButton />
    </li>
    <li className="nav-item">
      <SelectStackButton />
    </li>
    <li className="nav-item">
      <SettingButton />
    </li>
    <li className="nav-item">
      <LogoutButton />
    </li>
  </ul>
);

export default AppFooter;
