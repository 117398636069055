import React, { FunctionComponent } from 'react';
import { Center } from '../Primitives/Center/Center';
import { classnames } from 'tailwindcss-classnames';

type Props = {
  main: string;
  small?: string;
  dataTest?: string;
  invisible?: boolean;
};

const Card: FunctionComponent<Props> = ({
  main,
  dataTest,
  small,
  invisible,
}) => {
  const cn = classnames('bg-white', 'shadow-md', 'rounded-lg', {
    invisible,
  });

  return (
    <div data-test={dataTest} className={cn}>
      <Center>
        <div className="p-8 text-center">
          <div className="text-3xl text-blue-900 font-mono">{main}</div>
          <div className="small">{small}</div>
        </div>
      </Center>
    </div>
  );
};

export default Card;
