import { FunctionComponent, useEffect } from 'react';
import './styles.css';

const setViewHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const SettingStyles: FunctionComponent = () => {
  useEffect(() => {
    setViewHeight();
    window.addEventListener('resize', setViewHeight);
  });

  return null;
};

export default SettingStyles;
