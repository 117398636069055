import React, { FunctionComponent } from 'react';
import { Center } from '../Primitives/Center/Center';
import AppFooter from './AppFooter/AppFooter';
import ModalEngine from '../Modal/ModalEngine';
import Notes from '../Note/Notes';

const AppLayout: FunctionComponent = ({ children }) => {
  return (
    <>
      <Notes />
      <ModalEngine />
      <div className="h-full w-full flex flex-col bg-blue-50">
        <main className="flex-grow overflow-y-auto">
          <Center>{children}</Center>
        </main>

        <footer data-test="footer" className="flex-shrink-0">
          <AppFooter />
        </footer>
      </div>
    </>
  );
};

export default AppLayout;
