import React, { FunctionComponent } from 'react';
import { SuggType } from '../../database/model/EntityTypes';
import UserShortProfile from '../UserShortProfile/UserShortProfile';
import CardStack from './CardStack';
import { Center } from '../Primitives/Center/Center';
import mutate from '../../store/mutate';
// import Sugg from './Sugg';

interface OwnProps {
  sugg: SuggType;
}

type Props = OwnProps;

const UserSuggs: FunctionComponent<Props> = ({ sugg }) => {
  return (
    <div className="h-full flex flex-col">
      <UserShortProfile profile={sugg.userProfile} />
      <div className="flex-grow overflow-hidden relative">
        <Center>
          <CardStack
            cards={sugg.suggs}
            onSwipeLeft={(card) => {
              mutate.declineSugg({
                cardId: card.cardId,
                collId: card.collId,
              });
            }}
            onSwipeRight={(card) => {
              mutate.addCardToQueue({
                cardId: card.cardId,
                collId: card.collId,
              });
            }}
          />
        </Center>
      </div>
    </div>
  );
};

export default UserSuggs;
