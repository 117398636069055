import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classnames } from 'tailwindcss-classnames';
import Button from '../../Primitives/Button/Button';
import Loader from '../../Primitives/Loader/Loader';
import Stack from '../Stack/Stack';
import rootStore from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import mutate from '../../../store/mutate';

const AddStack: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { isLoading: isLoadingColls, data: dataColls } = rootStore.colls;
  const {
    isLoading: isLoadingUser,
    data: dataUserStacks,
  } = rootStore.userStacks;

  if (isLoadingColls || isLoadingUser) {
    return <Loader />;
  }

  // find in colls that are not in userStacks
  const collsToShow = dataColls!.filter(
    (c) => !dataUserStacks!.find((us) => us.collId === c.collId)
  );

  if (collsToShow.length === 0) {
    return null;
  }

  const cn = classnames('overflow-hidden', 'transition-all', 'transform-gpu', {
    'h-0': !open,
    'opacity-0': !open,
    'opacity-100': open,
    'scale-y-0': !open,
    'scale-y-100': open,
  });

  return (
    <>
      <div className={cn}>
        {dataColls?.map((c) => (
          <div
            key={c.collId}
            className="opacity-50"
            onClick={() => {
              mutate.addStack({
                stack: {
                  ...c,
                },
              });
            }}
          >
            <Stack coll={c} />
          </div>
        ))}
      </div>

      {!open && (
        <Button dataTest="add-stack" cb={() => setOpen(!open)}>
          {t('add stack')}
        </Button>
      )}
    </>
  );
};

export default observer(AddStack);
