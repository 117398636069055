import React, { FunctionComponent } from 'react';
import Counter from './Counter';
import ThisCard from './ThisCard';

const LearningDashboard: FunctionComponent = () => {
  return (
    <div className="p-2 flex justify-between">
      <Counter />
      <div className="text-right">
        <ThisCard />
      </div>
    </div>
  );
};

export default LearningDashboard;
