import React, { FunctionComponent } from 'react';
import { ShortProfile } from '../../database/model/EntityTypes';
import { smallText } from '../../twStyles/twStyles';

interface OwnProps {
  profile: ShortProfile;
}

type Props = OwnProps;

const UserShortProfile: FunctionComponent<Props> = ({ profile }) => {
  return (
    <div
      data-test="user_short"
      className="inline-flex rounded-full bg-blue-100 items-center"
    >
      <img className="rounded-full h-16" src={profile.picture} alt="avatar" />
      <div className="m-3 mr-5">
        <div>{profile.name}</div>
        <div className={smallText}>aka {profile.nickname}</div>
      </div>
    </div>
  );
};

export default UserShortProfile;
