import { makeAutoObservable } from 'mobx';
import rootStore from './rootStore';

interface StateNode {
  id: string;
  default?: string;
  states?: StateNode[];
  beforeEnter?: {
    cond: () => boolean;
    act: () => void;
  };
}

const stackIdRequried = {
  cond: () => !rootStore.collId,
  act: () => {
    // @ts-ignore
    rootStore.state.goto(['app', 'selectStack']);
  },
};

// @ts-ignore
const schemaGenerator = (): StateNode => ({
  id: 'root',
  default: 'intro',
  states: [
    { id: 'intro' },
    { id: 'login' },
    {
      id: 'app',
      default: 'learning',
      states: [
        {
          id: 'learning',
          default: 'question',
          states: [{ id: 'question' }, { id: 'answers' }, { id: 'revision' }],
          beforeEnter: stackIdRequried,
        },
        {
          id: 'addCard',
          beforeEnter: stackIdRequried,
          default: 'suggestions',
          states: [{ id: 'suggestions' }, { id: 'addNewCard' }],
        },
        { id: 'selectStack' },
        { id: 'settings' },
      ],
    },
  ],
});

const getChildStateNode = (parent: StateNode, id: string): StateNode => {
  const ret = parent.states?.find((s) => s.id === id);
  if (!ret) {
    throw Error(`cannot find child ${id} in parent ${parent.id}`);
  }
  return ret;
};

export class StateStore {
  public statePath: string[] = [];
  //  private readonly $rootStore;

  constructor(
    initialState: string[] = []
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // @ts-ignore
    //    rootStore
  ) {
    //    this.$rootStore = rootStore;
    if (initialState.length > 0) {
      this.statePath = initialState;
    } else {
      this.goto(initialState);
    }
    makeAutoObservable(this);
  }

  private get schema(): StateNode {
    return schemaGenerator();
  }

  private getStateNodesInThree(path: string[]): StateNode[] {
    const ret = [this.schema];
    const pathL = [...path];
    while (pathL.length > 0) {
      ret.push(getChildStateNode(ret[ret.length - 1], pathL.shift() as string));
    }
    return ret;
  }

  goto(statePath: string[]): void {
    const allNodesInTree = this.getStateNodesInThree(statePath);
    const lastStateNode = allNodesInTree[allNodesInTree.length - 1];
    if (lastStateNode.default) {
      this.goto([...statePath, lastStateNode.default]);
      return;
    }

    // find any beforeEnter
    const found = allNodesInTree.find(
      (n) => n.beforeEnter && n.beforeEnter.cond()
    );
    if (found) {
      found.beforeEnter?.act();
      return;
    }

    this.statePath = statePath;
  }
}
